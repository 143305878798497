import { Gizmo, IIndex, IIndexColumn, useGizmo } from "flowy-3-core";
import { FC, useEffect, useState } from "react";
import { Table as AntTable } from "../../daq/AnnexForm/AnnexForm.styles";
import { GizmoWrapper } from "../../utils";
import * as S from "./Table.styles";
import clone from "clone";

type TableProps = {
  gizmo: Gizmo;
};

const Table: FC<TableProps> = ({ gizmo }) => {
  const { binder, features, errors } = useGizmo({ gizmo });
  const [columns, setColumns] = useState<IIndexColumn[] | undefined>(undefined);
  const [rows, setRows] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    if (binder) {
      const index: IIndex | undefined = binder.getIndex();
      if (index) {
        setColumns(index.columns);
        setRows(index.rows);
      }

      binder.index.subscribe((index: IIndex) => {
        if (index) {
          setColumns(clone(index.columns));
        }
      });
    }
  }, []);

  const renderColumn = (column: IIndexColumn) => {
    const col: any = {
      title: column.label,
      key: column.fid,
    };

    if (column.fid === "title") {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });

        if (column.fid === "title") {
          if (data.mmd) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={row.title.imageUrl}
                  alt={row.title.fid}
                  style={{ height: row.title.elements > 4 ? 100 : 50 }}
                />
              </div>
            );
          } else {
            return <span key={column.fid}>{data.v}</span>;
          }
        }
      };
    } else if (column.gizmo) {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });

        if (data.gizmo) {
          console.log(data.v);

          return (
            <div key={column.fid}>
              <S.NumberField
                value={data.v}
                onChange={async (value) => {
                  await binder?.cellInteraction({ column, row, value });
                }}
                parser={(value) => {
                  return value!.replace(/\D/g, "");
                }}
                formatter={(value) => String(Number(value))}
              />
            </div>
          );
        } else {
          return <span key={column.fid}>{data.v}</span>;
        }
      };
    }

    return col;
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {columns && rows && (
        <AntTable
          columns={columns?.map(renderColumn)}
          dataSource={rows?.map((row) => row)}
          pagination={false}
        />
      )}
    </GizmoWrapper>
  );
};

export default Table;
