import React, { FC, useState, useEffect } from "react";
import { Gizmo, IIndex, IIndexColumn, useGizmo } from "flowy-3-core";
import * as S from "./AnnexForm.styles";
import { Errors, GizmoDisplay, GizmoWrapper } from "../../utils";
import FlowyFoundation from "../../flowy/FlowyFoundation/FlowyFoundation";
import { Button, Divider, Form, Popconfirm } from "antd";

type AnnexFormProps = {
  gizmo: Gizmo;
};

// The objective of this variable is to be increased every time we're opening a new
// FlowyFoundation. This will make the newly opened form to appear at the top.

const { Item } = Form;

const AnnexForm: FC<AnnexFormProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });
  const [params, setParams] = useState<any | undefined>(undefined);
  const [columns, setColumns] = useState<IIndexColumn[] | undefined>();
  const [rows, setRows] = useState<any[] | undefined>();

  useEffect(() => {
    if (binder) {
      const initIndex = binder.getIndex();
      if (initIndex) {
        setColumns(initIndex.columns);
        setRows(initIndex.rows);
      }

      binder.notices.subscribe((_: any) => {
        setParams(undefined);
      });
      binder.index.subscribe((index: IIndex) => {
        // console.log('=============index: ', index);
        setColumns(index.columns);
        setRows(index.rows);
      });
    }
  }, [binder]);

  const handleCreateButtonClick = async () => {
    if (binder) {
      const newParams = binder.getNewAnnexFormParams();
      // console.log('=============createButton->nP: ', newParams);
      setParams(newParams);
    }
  };

  const handleOperationButtonClick = async (data: any, row: any) => {
    // console.log('bbbbbbbbbbb.clic->data: ', data, ', row: ', row);
    if (data.op === "register.edit") {
      if (binder) {
        const editParams = binder.getAnnexFormParams({ row });
        // console.log('=============editButton->eP: ', editParams);
        setParams(editParams);
      }
    } else if (data.op === 'register.show')  {
      const params = binder?.getAnnexFormParams({row});
      setParams(params);
    } else if (data.op === "register.delete") {
      if (binder) {
        await binder.deleteAnnexForm({ row });
      }
    }
  };

  /**
   * Method that will be used by the table component to render the columns
   *
   * @param {IIndexColumn} column column configuration
   * @returns
   */
  const renderColumn = (column: IIndexColumn) => {
    const col: any = {
      title: column.label,
      key: column.fid,
    };

    if (column.type === undefined) {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });

        return <span>{data?.v}</span>;
      };
    } else if (column.type === "operations") {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });

        return (
          <>
            {data.map((d: any, i: number) => {
              return (
                <span key={i}>
                  {i > 0 && <Divider type="vertical" />}
                  {d.op === "register.delete" ? (
                    <Popconfirm
                      title="Eliminar pregunta!"
                      description="Estás seguro de eliminar la pregunta?"
                      onConfirm={() => handleOperationButtonClick(d, row)}
                      okText="Sí"
                      cancelText="No"
                    >
                      <Button key={d.f_id}>{d.v}</Button>
                    </Popconfirm>
                  ) : (
                    <Button
                      key={d.f_id}
                      onClick={() => handleOperationButtonClick(d, row)}
                    >
                      {d.v}
                    </Button>
                  )}
                </span>
              );
            })}
          </>
        );
      };
      col.width = "35%";
      col.align = "center";
    }

    return col;
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      <S.AnnexForm id={`annex_form-${config.fid}`}>
        <Button onClick={handleCreateButtonClick} type="primary">
          Crear
        </Button>

        <S.Table
          columns={columns?.map(renderColumn)}
          dataSource={rows?.map((row) => row)}
          pagination={{ size: "small", pageSize: 5 }}
          scroll={{ x: 400 }}
          rowKey="fid"
        />

        {params && (
          <S.AnnexFormModalContainer
            open={params !== undefined}
            footer={null}
            width="90%"
            bodyStyle={{ minHeight: "90vh" }}
            style={{ top: "3vh" }}
            maskClosable={false}
            onCancel={() => setParams(undefined)}
          >
            <div id="anne">
              {/* <h1>AnnexForm Modal</h1> */}
              <FlowyFoundation
                parentRootGizmoFid={params.parentRootGizmoFid}
                params={{
                  rgfid: params.rootGizmoFid,
                  "data-fid": params["dataFid"],
                  "parent-root-gizmo-fid": params["parentRootGizmoFid"],
                  "parent-gizmo-fid": params["parentGizmoFid"],
                  "parent-data-id": params["parentDataId"],
                  "parent-data-fid": params["parentDataFid"],
                  "child-index": params["childIndex"],
                }}
              />
            </div>
          </S.AnnexFormModalContainer>
        )}
      </S.AnnexForm>
    </GizmoWrapper>
  );
};

export default AnnexForm;
