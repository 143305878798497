import { IIndexColumn, IndexBinder, IIndexRow } from "flowy-3-core";
import BooleanField from "./daq/BooleanField/BooleanField";

interface IRenderGizmoParams {
  binder: IndexBinder;
  column: IIndexColumn;
  row: IIndexRow;
}

/**
 * Render index Gizmo
 *
 * @param {IGizmo} gizmoConfig configuration of the gizmo that's going to be rendered
 * @returns
 */
function renderGizmo({
  binder,
  column,
  row,
}: IRenderGizmoParams): React.ReactNode {
  const type = column.gizmo?.type;
  const fid = column.gizmo?.fid;

  let gizmoComponent: React.ReactNode = (
    <div key={fid}>
      {/* Gizmo of type {type} not supported, fid: {gizmoConfig.fid}, label:{" "} */}
      {/* {gizmoConfig.label} */}
      Edit not supported
    </div>
  );

  if (type === "boolean_field") {
    gizmoComponent = (
      <BooleanField key={fid} binder={binder} column={column} row={row} />
    );
  }

  return gizmoComponent;
}

export { renderGizmo };
