import { IIndex } from "flowy-3-core";
import React, { useState } from "react";
import * as S from "./Index.styles";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useTheme } from "../../../../hooks/useTheme";

type SearchProps = {
  binder: any;
  onSearchResult: (searchIndex: IIndex) => void;
  setClearSearch: (search: boolean) => void;
  clearSearch: boolean;
};

const Search: React.FC<SearchProps> = ({
  binder,
  onSearchResult,
  setClearSearch,
  clearSearch,
}) => {
  const { colors } = useTheme();
  const [searchText, setSearchText] = useState<string>("");
  const [showClearSearch, setShowClearSearch] = useState<boolean>(false);

  const handleButtonClick = async (text: string) => {
    if (text) {
      const searchIndex = await binder.textSearch({ text });
      onSearchResult(searchIndex);
      setShowClearSearch(true);
    }
  };

  const handleClear = async () => {
    setSearchText("");
    // setClearSearch(!clearSearch);
    setShowClearSearch(false);
    await binder.textSearchClear();
  };

  const suffix = (
    <CloseCircleOutlined
      onClick={() => handleClear()}
      style={{
        fontSize: 16,
        color: colors.main,
        // display: searchText ? "block" : "none",
        display: showClearSearch ? "block" : "none",
      }}
    />
  );

  return (
    <S.Search
      value={searchText}
      onChange={(text) => setSearchText(text.target.value)}
      onSearch={handleButtonClick}
      placeholder="Buscar..."
      enterButton="Buscar"
      size="large"
      suffix={suffix}
    />
  );
};

export default Search;
