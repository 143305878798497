import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./PhoneField.styles";
import { GizmoWrapper } from "../../utils";
import { Col, Row } from "antd";
import PhoneVerification from "./PhoneVerification";

type PhoneFieldProps = {
  gizmo: Gizmo;
};

const PhoneField: FC<PhoneFieldProps> = ({ gizmo }) => {
  const { features, config, errors, binder } = useGizmo({ gizmo });
  const [value, setValue] = useState<string>("");
  const [showVerification, setShowVerification] = useState<boolean>(false);

  useEffect(() => {
    if (binder) {
      binder.value.subscribe((v: string) => {
        setValue(v);
      });
      binder.showCodeVerification.subscribe((v: boolean) => {
        setShowVerification(v);
      });

      const initialValue = binder.getValue();
      if (initialValue) {
        setValue(initialValue);
      }
    }
  }, [binder]);

  const handleChange = async (e: any) => {
    const { value } = e.target;

    await binder?.setValue(value);
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {features.editable === false ? (
        <span>{value ? binder?.getFormattedValue() : ""}</span>
      ) : (
        <Row gutter={20}>
          <Col sm={config.ops?.phoneField?.verificationCode ? 12 : 24} xs={24}>
            <S.PhoneField
              id={`number_field-${config.fid}`}
              mask={binder.getMask()}
              onChange={handleChange}
              value={value}
              type="tel"
            />
          </Col>
          {config.ops?.phoneField?.verificationCode && (
            <PhoneVerification
              binder={binder}
              showVerification={showVerification}
            />
          )}
        </Row>
      )}
    </GizmoWrapper>
  );
};

export default PhoneField;
