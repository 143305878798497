import { useNavigate, useLocation, useParams } from "react-router-dom";

interface IPlatformOperationCbParams {
  operation: any;
  params: any;
}

/**
 * Use platform callbacks, custom hook that will expose platform callbacks
 */
const usePlatformCB = () => {
  const routerNavigate = useNavigate();
  const routerLocation = useLocation();
  const routerParams = useParams();
  const params = getParams(routerParams, routerLocation);

  const platformOperationCB = ({
    operation,
    params,
  }: IPlatformOperationCbParams): Promise<any> => {
    return new Promise<any>(async (resolve) => {
      if (operation === "navigation.link" && params.path) {
        const rootPath = routerLocation.pathname.split("/")[1];
        // The new location is the current location plus the configured one
        // const newLocation = "/" + rootPath + params.path;
        // TODO: assigning directly params.path to the newLocation works when we're on the root
        // but it doesn't work when we're on a subpath, like /f/. So we have to deal with that
        const newLocation = params.path;

        if (params.newTab === true) {
          window.open(`/#${newLocation}`, "_blank");
        } else {
          routerNavigate(newLocation);
        }
      } else if (operation === "navigation.back") {
        if (routerLocation.key === "default") {
          // window.close();
          routerNavigate("/");
        } else {
          routerNavigate(-1);
        }
      }

      resolve(undefined);
    });
  };

  return {
    params,
    platformOperationCB,
  };
};

/**
 * Get parameters from the react-router-dom.
 */
const getParams = (routerParams: any, routerLocation: any) => {
  const params: any = {};

  if (routerLocation.state) {
    for (const s in routerLocation.state) {
      params[s] = routerLocation.state[s];
    }
  }

  for (const paramKey in routerParams) {
    if (paramKey !== "*") {
      params[paramKey] = routerParams[paramKey];
    }
  }

  if (routerLocation.search) {
    const urlParams = new URLSearchParams(routerLocation.search);
    const paramKeys = Array.from(urlParams.keys());

    for (const paramKey of paramKeys) {
      // fid is a reserved param key
      // if (paramKey !== 'fid') {
      params[paramKey] = urlParams.get(paramKey);
      // }
    }
  }

  if (routerLocation.pathname === "/p/rg") {
    params["public"] = true;
  }

  return params;
};

export default usePlatformCB;
