import { FC, useEffect, useState } from "react";
import { Gizmo, IIndex, IIndexColumn, useGizmo } from "flowy-3-core";
import { GizmoWrapper } from "../../utils";
import { Table as AntTable } from "../../daq/AnnexForm/AnnexForm.styles";
import { Button, Divider } from "antd";

type UnionTableProps = {
  gizmo: Gizmo;
};

const UnionTable: FC<UnionTableProps> = ({ gizmo }) => {
  const { binder, features, errors } = useGizmo({ gizmo });
  const [columns, setColumns] = useState<IIndexColumn[] | undefined>(undefined);
  const [rows, setRows] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    if (binder) {
      const index = binder.getIndex();
      console.log("index", index);
      if (index) {
        setColumns(index.columns);
        setRows(index.rows);
      }
    }
  }, []);

  const renderColumn = (column: IIndexColumn) => {
    const col: any = {
      title: column.label,
      key: column.fid,
    };

    if (
      column.fid === "rootGizmoLabel" ||
      column.fid === "rootGizmoDataLabel"
    ) {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });

        return <span>{data.v}</span>;
      };
    } else if (column.type === "operations") {
      col.render = (row: any) => {
        const operations = binder.getCell({ column, row });

        return operations.map((operation: any, index: number) => {
          return (
            <span key={index}>
              {index > 0 && <Divider type="vertical" />}
              <Button
                onClick={() => {
                  handleCellClick({ column, row, operation: operation.op });
                }}
              >
                {operation.v}
              </Button>
            </span>
          );
        });
      };
    }

    return col;
  };

  const handleCellClick = ({ column, row, operation }: IHandleCellClick) => {
    binder.cellInteraction({ column, row, operation });
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {columns && rows && (
        <AntTable
          columns={columns.map(renderColumn)}
          dataSource={rows.map((row) => row)}
          pagination={false}
          rowKey={"fid"}
        />
      )}
    </GizmoWrapper>
  );
};

interface IHandleCellClick {
  column: IIndexColumn;
  row: any;
  operation: any;
}

export default UnionTable;
