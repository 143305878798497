import { FC, useEffect, useState } from "react";
import * as S from "./FormFloatingInfo.styles";
import { Button, Timeline } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IGizmoFeatures } from "flowy-3-core";
import { CloseOutlined } from "@ant-design/icons";

type FormFloatingInfoProps = {
  features: IGizmoFeatures;
  visible: boolean;
  handleVisible: (value: boolean) => void;
};

const FormFloatingInfo: FC<FormFloatingInfoProps> = ({
  features,
  visible,
  handleVisible,
}) => {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const updateVisibility = () => {
      if (window.matchMedia("(max-width: 770px)").matches) {
        handleVisible(false);
      } else {
        handleVisible(true);
      }
    };

    updateVisibility();

    const mediaQuery = window.matchMedia("(max-width: 770px)");
    const handleResize = () => updateVisibility();

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  useEffect(() => {
    const items = features.form?.dataHistory?.map((h) => {
      const item = {
        id: h.status.f_id,
        color: h.status.color,
        children: (
          <>
            <p>Usuario: {h.username}</p>
            <p>Fecha: {h.createdAt}</p>
            <p>Status: {h.status.title}</p>
          </>
        ),
      };

      return item;
    });

    if (items) {
      setItems(items);
    }
  }, [features]);

  const handleClose = () => {
    handleVisible(!visible);
  };

  return (
    <S.FormFloatingInfo>
      <Button
        style={{
          position: "absolute",
          right: 90,
          top: 150,
        }}
        onClick={handleClose}
        shape="circle"
        icon={<InfoCircleOutlined />}
      />
      <S.Card
        title={
          <div>
            Historial
            <Button
              type="text"
              onClick={handleClose}
              style={{ float: "right" }}
            >
              <CloseOutlined />
            </Button>
          </div>
        }
        bordered={false}
        style={{
          backgroundColor: "white",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          height: "98vh",
          overflowY: "auto",
          transition: "transform 0.3s ease-out",
          transform: visible ? "translateX(0)" : "translateX(130%)",
        }}
      >
        <Timeline items={items} />
      </S.Card>
    </S.FormFloatingInfo>
  );
};

export default FormFloatingInfo;
