import { default as KanbanIndex } from "./KanbanIndex";

export interface IStatus {
  id: number;
  f_id: string;
  title: string;
  color: string;
}

export default KanbanIndex;
