import { useEffect, useState } from "react";
import { IGlobalTheme, useFlowyGlobalContext } from "flowy-3-core";

export const useTheme = () => {
  const flowyGlobal = useFlowyGlobalContext();
  const [colors, setColors] = useState({
    main: "#E23151",
    mainLoading: "#F38498",
    mainPressed: "#F94667",
    secondary: "#2D3E53",
    secondaryLoading: "#F38498",
    secondaryPressed: "#F94667",
    accessories: "#E8E8E8",
    background: "#FFFFFF",
    navbar: "#FFFFFF",
    font: "#000000",
    error: "#EA2E2E",
    errorBackground: "#FFDDDD",
    disabledBackground: "#DBDBDB",
    disabledText: "#A3A3A3",
  });
  const [navbarLabel, setNavbarLabel] = useState("Formas");
  const [navbarLogo, setNavbarLogo] = useState<any>(undefined);
  const [backgroundImage, setBackgroundImage] = useState<any>(undefined);

  useEffect(() => {
    const initialTheme = flowyGlobal?.getGlobalTheme();

    if (initialTheme) {
      if (initialTheme.colors) {
        setColors({
          ...initialTheme.colors,
          mainLoading: "#F38498",
          mainPressed: "#F94667",
          secondaryLoading: "#F38498",
          secondaryPressed: "#F94667",
          font: "#000000",
          error: "#EA2E2E",
          errorBackground: "#FFDDDD",
          disabledBackground: "#DBDBDB",
          disabledText: "#A3A3A3",
        });
      }
      if (initialTheme.navbar?.logo) {
        const localStorageField = initialTheme.navbar.logo.path
          ? initialTheme.navbar.logo.path
          : "navbarLogo";
        const storedNavbarLogo = localStorage.getItem(localStorageField);

        setNavbarLogo(storedNavbarLogo);
      }
      if (initialTheme.background?.image) {
        const localStorageField = initialTheme.background.image.path
          ? initialTheme.background.image.path
          : "backgroundImage";
        const storedBackgroundImage = localStorage.getItem(localStorageField);
        setBackgroundImage(storedBackgroundImage);
      }
      if (initialTheme.navbar?.label) {
        setNavbarLabel(initialTheme.navbar.label);
      } else {
        setNavbarLabel("Formas");
      }
    }

    flowyGlobal?.globalTheme.subscribe((theme: IGlobalTheme) => {
      if (theme.colors) {
        setColors({
          ...theme.colors,
          mainLoading: "#F38498",
          mainPressed: "#F94667",
          secondaryLoading: "#F38498",
          secondaryPressed: "#F94667",
          font: "#000000",
          error: "#EA2E2E",
          errorBackground: "#FFDDDD",
          disabledBackground: "#DBDBDB",
          disabledText: "#A3A3A3",
        });
      }
      if (theme.background?.image) {
        const localStorageField = theme.background.image.path
          ? theme.background.image.path
          : "backgroundImage";
        const storedBackgroundImage = localStorage.getItem(localStorageField);

        setBackgroundImage(storedBackgroundImage);
      }
      if (theme.navbar?.logo) {
        const localStorageField = theme.navbar.logo.path
          ? theme.navbar.logo.path
          : "navbarLogo";
        const storedNavbarLogo = localStorage.getItem(localStorageField);

        setNavbarLogo(storedNavbarLogo);
      }
      if (theme.navbar?.label) {
        setNavbarLabel(theme.navbar.label);
      } else {
        setNavbarLabel("Formas");
      }
    });
  }, [backgroundImage]);

  return {
    colors,
    navbarLabel,
    navbarLogo,
    backgroundImage,
  };
};
