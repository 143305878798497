import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Indicator.styles";

type IndicatorProps = {
  gizmo: Gizmo;
};

const Indicator: FC<IndicatorProps> = ({ gizmo }) => {
  const { features, config } = useGizmo({ gizmo });
  const [value, setValue] = useState<number | undefined>();

  useEffect(() => {
    //! Use the binder instead of the gizmo
    const data: any = gizmo.getData();
    if (data && data["v"] !== undefined) {
      setValue(data["v"]);
    }
    gizmo.data.subscribe((data: any) => {
      if (data && data["v"] !== undefined) {
        setValue(data["v"]);
      }
    });
  }, [gizmo]);

  return (
    <S.Indicator
      id={`indicator-${gizmo.getConfig().fid}`}
      columns={gizmo.getColumns()}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#E23151",
        height: 100,
        width: "95%",
        borderRadius: 6,
        margin: 5,
      }}
    >
      <label style={{ color: "white", fontSize: 24, fontWeight: "bold" }}>
        {features.label}
      </label>
      {value !== undefined && (
        <div
          style={{
            color: "white",
            fontSize: 24,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {value}
        </div>
      )}
    </S.Indicator>
  );
};

export default Indicator;
