import { Card as RNCard, Row } from "antd";
import { SCREEN_SIZES } from "flowy-3-core";
import styled from "styled-components";

const screenMaxWidths = {
  small: `(max-width: ${SCREEN_SIZES.small})`,
  medium: `(max-width: ${SCREEN_SIZES.medium})`,
  large: `(max-width: ${SCREEN_SIZES.large})`,
  extraLarge: `(min-width: ${SCREEN_SIZES.extraLarge})`,
};

const FormFloatingInfo = styled(Row)`
  position: fixed;
  right: 0px;
  top: 0px;
  margin: 10px;
  z-index: 1;
`;

const Card = styled(RNCard)`
  @media ${screenMaxWidths.extraLarge} {
    width: 28vw;
  }
  @media ${screenMaxWidths.large} {
    width: 28vw;
  }
  @media ${screenMaxWidths.medium} {
    width: 28vw;
  }
  @media ${screenMaxWidths.small} {
    width: 100vw;
  }
`;

export { FormFloatingInfo, Card };
