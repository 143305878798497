import { useEffect, useState } from "react";
import { Gizmo, IGizmo, useGizmo } from "flowy-3-core";
import { Table } from "./DataTable.styles";

type DataTableProps = {
  gizmo: Gizmo;
};

interface IColumn {
  label: string;
  field: string;
}

const DataTable = ({ gizmo }: DataTableProps) => {
  const { features, binder } = useGizmo({ gizmo });
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    const data: any = gizmo.getData();

    if (data?.dtd?.columns && data?.dtd?.rows) {
      setColumns(data.dtd.columns);
      setRows(data.dtd.rows);
    }
  }, []);

  const renderColumn = (column: IColumn) => {
    const col: any = {
      title: column.label,
      key: column.field,
    };

    col.render = (row: any) => {
      const data = binder?.getCell({ column, row });
      return <span>{data?.v}</span>;
    };

    return col;
  };

  return (
    <div>
      <span>{features.label}</span>
      {columns && rows && (
        <Table
          columns={columns.map(renderColumn)}
          dataSource={rows}
          pagination={false}
          rowKey="id"
        />
      )}
    </div>
  );
};

export default DataTable;
