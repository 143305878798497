import { FC, useEffect, useState } from "react";
import { IIndexKanbanColumn, KanbanTypeBinder } from "flowy-3-core";
import { useDrop } from "react-dnd";
import Card from "./Card";

type ColumnProps = {
  binder: KanbanTypeBinder;
  column: IIndexKanbanColumn;
};

const Column: FC<ColumnProps> = ({ column, binder }) => {
  const [cards, setCards] = useState<any[]>([]);
  const [{ canDrop }, drop] = useDrop({
    accept: "CARD",
    drop: (item: any) => {
      binder.moveRowToColumn({
        row: item.row,
        originColumnStatus: item.columnStatus,
        destinyColumnStatus: column.status,
      });
    },
    canDrop: (item) => {
      return binder.canDropRow({
        row: item.row,
        originColumnStatus: item.columnStatus,
        destinyColumnStatus: column.status,
      });
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
    }),
  });

  useEffect(() => {
    setCards(column.rows);
  }, [column.rows]);

  return (
    <div
      ref={drop}
      style={{
        flex: 1,
        margin: "0 10px",
        padding: "10px",
        borderRadius: "5px",
        minHeight: "300px",
        backgroundColor: column.status.color,
        opacity: canDrop ? 0.5 : 1,
      }}
    >
      <h1>Column: {column.status.title}</h1>
      {cards.map((card: any, index: number) => (
        <Card
          key={card.id}
          binder={binder}
          row={card}
          index={index}
          columnStatus={column.status}
        />
      ))}
    </div>
  );
};

export default Column;
