import { FC, useState } from "react";
import { RootGizmo, useRootGizmo } from "flowy-3-core";
import * as S from "./Form.styles";
import renderGizmos from "../../render-gizmos";
import FormInfo from "./FormInfo";
import Status from "./Status";
import Errors from "../../utils/Errors/Errors";
import FormFloatingInfo from "./FormFloatingInfo";

type FormProps = {
  gizmo: RootGizmo;
};

const Form: FC<FormProps> = ({ gizmo }) => {
  const { features, screenGizmos, errors } = useRootGizmo({ gizmo });
  const [visible, setVisible] = useState(true);

  const handleVisible = (value: boolean) => {
    setVisible(value);
  };

  return (
    <div style={{ display: "flex" }}>
      <S.Form
        id={`form-${gizmo.getConfig().fid}`}
        style={{
          width:
            visible && !window.matchMedia("(max-width: 770px)").matches
              ? "75%"
              : "100%",
        }}
      >
        {gizmo.getParams()["m"] === "s" && <FormInfo gizmo={gizmo} />}
        <Status rootGizmo={gizmo} />
        {features.displayLabel && <h1>{features.label}</h1>}
        {screenGizmos.length > 0 && renderGizmos(screenGizmos)}
        <Errors errors={errors} />
      </S.Form>
      {features.form?.dataHistory && (
        <FormFloatingInfo
          features={features}
          visible={visible}
          handleVisible={handleVisible}
        />
      )}
    </div>
  );
};

export default Form;
