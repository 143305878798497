import { IIndexKanbanColumn, KanbanTypeBinder } from "flowy-3-core";
import { FC, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Column from "./Column";

type KanbanIndexProps = {
  binder?: KanbanTypeBinder;
};

const KanbanIndex: FC<KanbanIndexProps> = ({ binder }) => {
  const [columns, setColumns] = useState<IIndexKanbanColumn[]>([]);

  useEffect(() => {
    const columns = binder?.getKanbanColumns();
    binder?.kanbanColumns.subscribe((cols: IIndexKanbanColumn[]) => {
      setColumns(cols);
    });
    if (columns && Array.isArray(columns)) {
      setColumns(columns);
    }
  }, []);

  return (
    <>
      <h1>KanbanIndex</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "20px",
          backgroundColor: "#f0f0f0",
        }}
      >
        <DndProvider backend={HTML5Backend}>
          {binder &&
            columns.map((column: IIndexKanbanColumn) => (
              <Column
                key={column.status.f_id}
                column={column}
                binder={binder}
              />
            ))}
        </DndProvider>
      </div>
    </>
  );
};

export default KanbanIndex;
