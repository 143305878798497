import { Gizmo, IGizmoData, IGizmoFeatures, gnov } from "flowy-3-core";
import React, { useState, useEffect, CSSProperties } from "react";

type ProgressBarProps = {
  features: IGizmoFeatures;
  gizmo: Gizmo;
};

const ProgressBar = ({ features, gizmo }: ProgressBarProps) => {
  const [percentage, setPercentage] = useState<number>(0);
  const [goal, setGoal] = useState<number | undefined>(undefined);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [diff, setDiff] = useState<number | undefined>(undefined);

  useEffect(() => {
    const data: any = gizmo.getData();
    setData(data);
  }, []);

  const setData = (data: IGizmoData) => {
    if (data) {
      const percentage = gnov(data, "pd.percentage");
      const progress = gnov(data, "pd.progress");
      const goal = gnov(data, "pd.goal");
      const diff = gnov(data, "pd.diff");
      if (percentage) {
        setPercentage(percentage);
      }
      if (progress !== undefined) {
        setProgress(progress);
      }
      if (goal !== undefined) {
        setGoal(goal);
      }
      if (diff !== undefined) {
        setDiff(diff);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#E8E8E8",
        borderRadius: 6,
        padding: 20,
        margin: 5
      }}
    >
      {React.cloneElement(
        <div
          style={{
            color: "#000",
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          {features.label}
        </div>
      )}

      <div style={styles.container}>
        <div
          style={{
            width: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={styles.text}>{`${percentage}%`}</div>
        </div>

        <div
          style={{
            backgroundColor: "#A3A3A3",
            height: "100%",
            width: "100%",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              backgroundColor: "#E23151",
              height: "100%",
              width: `${percentage}%`,
              borderRadius: 10,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          {progress !== undefined && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 20,
                  backgroundColor: "#E23151",
                  borderRadius: 6,
                }}
              />
              <div>Progreso: {progress}</div>
            </div>
          )}
          {diff !== undefined && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 20,
                  backgroundColor: "#A3A3A3",
                  borderRadius: 6,
                }}
              />
              <div>Faltantes: {diff}</div>
            </div>
          )}
        </div>
        <div>{goal !== undefined && <div>Meta: {goal}</div>}</div>
      </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    width: "100%",
    height: 50,
    borderRadius: 10,
    overflow: "hidden",
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
    padding: 3,
    marginBottom: 10,
    gap: 5,
  },
  progressBar: {
    height: "100%",
    width: "50%",
    backgroundColor: "red",
  },
  text: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 20,
  },
};

export default ProgressBar;
