import { IRootGizmoDataStatus, KanbanTypeBinder } from "flowy-3-core";
import { FC, useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";

type CardProps = {
  binder: KanbanTypeBinder;
  row: any;
  /**
   * Row index in the column array
   */
  index: number;
  /**
   * Column status where the card is placed
   */
  columnStatus: IRootGizmoDataStatus;
};

const Card: FC<CardProps> = ({ binder, row, index, columnStatus }) => {
  const [label, setLabel] = useState<string>();
  const [{ isDragging }, drag] = useDrag({
    type: "CARD",
    item: { index, fid: row.fid, row, columnStatus },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // end: (item, monitor) => {
    //   console.log("end.item: ", item);
    //   console.log(
    //     "didDrop: ",
    //     monitor.didDrop(),
    //     ", result: ",
    //     monitor.getDropResult()
    //   );
    // },
  });

  useEffect(() => {
    const content = binder.getRowContent({ row });
    if (content?.label) {
      setLabel(content.label);
    }
  }, []);

  return (
    <div
      ref={drag}
      style={{
        padding: "10px",
        marginBottom: "10px",
        backgroundColor: "white",
        border: "1px solid #ddd",
        borderRadius: "3px",
        cursor: "move",
        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        transition: "background-color 0.3s ease",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {label && label}
    </div>
  );
};

export default Card;
