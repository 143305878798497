import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import { VictoryChart, VictoryBar, VictoryArea, VictoryLine } from "victory";
import * as S from "./Chart.styles";

type ChartProps = {
  gizmo: Gizmo;
};

const Chart: FC<ChartProps> = ({ gizmo }) => {
  const { features, binder } = useGizmo({ gizmo });
  const [data, setData] = useState<any[] | undefined>();
  const [chartType, setChartType] = useState<string>("bar");

  useEffect(() => {
    if (binder) {
      let type = "bar";
      if (binder.getConfig().ops?.chart?.type) {
        type = binder.getConfig().ops?.chart?.type;
      }

      setChartType(type);
      setData(binder.getData());
    }
  }, [binder]);

  return (
    <S.Chart
      id={`chart-${gizmo.getConfig().fid}`}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "80vh",
        justifyContent: "center",
        backgroundColor: "#E8E8E8",
        borderRadius: 6,
        margin: 5,
        paddingTop: 20,
      }}
    >
      <label style={{ color: "#000", fontSize: 24, fontWeight: "bold" }}>
        {features.label}
      </label>
      {data && (
        <VictoryChart domainPadding={20}>
          {chartType === "bar" && (
            <VictoryBar
              barRatio={1}
              alignment="start"
              data={data}
              style={{
                data: { fill: "#E23151" },
                labels: { fill: "#E23151" },
              }}
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
            />
          )}
          {chartType === "area" && <VictoryArea data={data} />}
          {chartType === "line" && <VictoryLine data={data} />}
        </VictoryChart>
      )}
    </S.Chart>
  );
};

export default Chart;
