import { Gizmo, useGizmo } from "flowy-3-core";
import ProgressBar from "./ProgressBar";

type ProgressProps = {
  gizmo: Gizmo;
};

const Progress = ({ gizmo }: ProgressProps) => {
  const { features } = useGizmo({ gizmo });

  return (
    <div>
      <ProgressBar features={features} gizmo={gizmo} />
    </div>
  );
};

export default Progress;
