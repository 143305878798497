import { FC, useState, useEffect } from "react";
import { IIndexColumn, IIndexRow, IndexBinder } from "flowy-3-core";
import { Switch } from "antd";

type BooleanFieldProps = {
  binder: IndexBinder;
  column: IIndexColumn;
  row: IIndexRow;
};

const BooleanField: FC<BooleanFieldProps> = ({ binder, column, row }) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    const data = binder.getCell({ column, row });
    if (data?.v === "Si") {
      setChecked(true);
    } else if (data?.v === "No") {
      setChecked(false);
    }
  }, [row]);

  const handleChange = async (value: boolean) => {
    await binder.cellInteraction({
      column,
      row,
      value,
    });
  };

  return (
    <Switch
      style={{ backgroundColor: checked ? "green" : "#DBDBDB" }}
      checked={checked}
      onChange={handleChange}
    />
  );
};

export default BooleanField;
